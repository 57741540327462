define(['app'], function(app) {

  var ResponsiveWorkoutWidget = function() {
    var self = this;
    self.app = app;

    // keep hard-coded values together here
    var _config = {
      YouTubeScriptURL: 'https://www.youtube.com/iframe_api',
      dataElementSelector: '[data-js-element=responsiveWorkoutWidgetVideo_item]',
      videoTitleSelector: '[data-js-element=responsiveWorkoutWidget_videoTitle]',
      playerElementSelector: '[data-js-element=responsiveWorkoutWidget_youtubeVideo]',
      phaseInputSelector: '[data-js-element=responsiveWorkoutWidget_phase]',
      weekInputSelector: '[data-js-element=responsiveWorkoutWidget_week]',
      dayInputSelector: '[data-js-element=responsiveWorkoutWidget_day]',
      dailyTipContentSelector: '[data-js-element=responsiveWorkoutWidget_dailyTipContent]',
      dailyTipHiddenClass: 'responsiveWorkoutWidget-noDailyTip',
    };

    var _properties = {
      weekLabelText: app.utils.getProperty('week', 'responsiveWorkoutWidget'),
      dayLabelText: app.utils.getProperty('day', 'responsiveWorkoutWidget'),
      workoutLabelText: app.utils.getProperty('workout', 'responsiveWorkoutWidget'),
    };

    // bind the parent element, load the youtube api, get the data items
    var _init = function(element) {
      self.element = element;
      self.items = _getData();
      // setup dropdowns
      self.phaseSelect = self.element.querySelector(self.config.phaseInputSelector);
      self.weekSelect = self.element.querySelector(self.config.weekInputSelector);
      self.daySelect = self.element.querySelector(self.config.dayInputSelector);
      _populateSelect(self.phaseSelect, '', Object.keys(self.items));
      _getPhase();
      _populateSelect(self.weekSelect, self.properties.weekLabelText, Object.keys(self.items[self.selectedPhase]));
      _getWeek();
      _populateSelect(self.daySelect, self.properties.dayLabelText, Object.keys(self.items[self.selectedPhase][self.selectedWeek]));
      _getDay();
      // add event listeners
      self.phaseSelect.addEventListener('change', function() {
        _getPhase();
        _populateSelect(self.weekSelect, self.properties.weekLabelText, Object.keys(self.items[self.selectedPhase]));
        _getWeek();
        _populateSelect(self.daySelect, self.properties.dayLabelText, Object.keys(self.items[self.selectedPhase][self.selectedWeek]));
        _getDay();
        _loadItem();
      });
      self.weekSelect.addEventListener('change', function() {
        _getWeek();
        _populateSelect(self.daySelect, self.properties.dayLabelText, Object.keys(self.items[self.selectedPhase][self.selectedWeek]));
        _getDay();
        _loadItem();
      });
      self.daySelect.addEventListener('change', function() {
        _getDay();
        _loadItem();
      });

      self.app.subscribe('yt_api_ready', _loadItem);
      _loadYouTubeAPIScript();
      return self;
    };

    var _getPhase = function() {
      self.selectedPhase = self.phaseSelect.options[self.phaseSelect.selectedIndex].value;
    };

    var _getWeek = function() {
      self.selectedWeek = self.weekSelect.options[self.weekSelect.selectedIndex].value;
    };

    var _getDay = function() {
      self.selectedDay = self.daySelect.options[self.daySelect.selectedIndex].value;
    };

    // non-blocking api script loader
    var _loadYouTubeAPIScript = function() {
      window.onYouTubeIframeAPIReady = function() {
        self.app.publish('yt_api_ready');
      };
      var tag = document.createElement('script');
      tag.src = self.config.YouTubeScriptURL;
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    };

    // load a data item
    var _loadItem = function() {
      var item = self.items[self.selectedPhase][self.selectedWeek][self.selectedDay];
      //update the video title
      if (!self.videoTitle) {
        self.videoTitle = self.element.querySelector(self.config.videoTitleSelector);
      }
      self.videoTitle.innerText = self.selectedPhase + ' - ' + self.properties.weekLabelText + ' ' + self.selectedWeek + ' - ' + self.properties.workoutLabelText + ' ' + self.selectedDay;
      // load the video
      if (!self.player) {
        var playerElement = self.element.querySelector(self.config.playerElementSelector);
        self.player = new window.YT.Player(playerElement, {
          width: '100%',
          height: '100%',
          videoId: item.videoID,
          playerVars: {
            rel: 0,
            modestbranding: 0,
            showinfo: 0
          }
        });
      } else {
        self.player.loadVideoById(item.videoID);
      }
      // display the daily tip if present
      if (!self.dailyTipContent) {
        self.dailyTipContent = self.element.querySelector(self.config.dailyTipContentSelector);
      }
      if (item.dailyTip.trim() === '') {
        self.app.element.init(self.element).addClass(self.config.dailyTipHiddenClass);
      } else {
        self.dailyTipContent.innerHTML = item.dailyTip;
        self.app.element.init(self.element).removeClass(self.config.dailyTipHiddenClass);
      }
    };

    // extract the youtube video id from any type of youtube url
    var _getYouTubeID = function(url) {
      var ID = '';
      url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_-]/i);
        ID = ID[0];
      } else {
        ID = url[0];
      }
      return ID;
    };

    // get the item data from the DOM
    var _getData = function() {
      var dataElements = self.element.querySelectorAll(self.config.dataElementSelector);
      var data = [];
      for (var i = 0; i < dataElements.length; i++) {
        var item = dataElements[i];
        data.push({
          phase: item.getAttribute('data-phase'),
          week: parseInt(item.getAttribute('data-week')),
          day: parseInt(item.getAttribute('data-day')),
          videoID: _getYouTubeID(item.getAttribute('data-videoURL')),
          dailyTip: item.innerHTML
        });
      }

      //sort
      data = data.sort(function(a, b) {
        if (a.phase < b.phase) {
          return -1;
        }
        if (a.phase > b.phase) {
          return 1;
        }
        var x = a.week - b.week;
        return x ? x : a.day - b.day;
      });

      // remove duplicate phase/week/day combinations
      if (data.length > 1) {
        for (i = 1; i < data.length; i++) {
          if (data[i - 1].phase === data[i].phase && data[i - 1].week === data[i].week && data[i - 1].day === data[i].day) {
            data.splice(i, 1);
            i--;
          }
        }
      }
      //create lookup
      return _getLookupFromArray(data, ['phase', 'week', 'day']);
    };

    var _getLookupFromArray = function(array, keys) {
      var lookup = {};
      for (var i = 0; i < array.length; i++) {
        var store = [];
        for (var j = 0; j < keys.length; j++) {
          if (j === 0) { // first element so set store[0] to the hash subtree to build
            store[j] = lookup[array[i][keys[j]]];
            if (!store[j]) {
              store[j] = lookup[array[i][keys[j]]] = {};
            }
          } else if (j < keys.length - 1) { // not first or last element so add the hash subtree its parent
            store[j] = store[j - 1][array[i][keys[j]]];
            if (!store[j]) {
              store[j] = store[j - 1][array[i][keys[j]]] = {};
            }
          } else { // last element so set the value to the original array element
            if (!store[j - 1][array[i][keys[j]]]) {
              store[j - 1][array[i][keys[j]]] = array[i];
            }
          }
        }
      }
      return lookup;

    };

    var _populateSelect = function(select, label, values) {
      select.options.length = 0;
      for (var i = 0; i < values.length; i++) {
        var o = document.createElement('option');
        o.setAttribute('value', values[i]);
        o.innerText = label + ' ' + values[i].toString();
        select.appendChild(o);
      }
    };

    self.config = _config;
    self.properties = _properties;
    self.init = _init;
  };

  return ResponsiveWorkoutWidget;

});
